import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { List } from "antd";

interface Errors {
    errs: Record<string, string> | null | undefined
}

export default function OptimizationWarning({ errs } : Errors) {
    if (errs == null || Object.keys(errs).length === 0) {
        return null;
    }

    const mapErrs = new Map(Object.entries(errs));
    let errsSlice = Array.from(mapErrs.keys());
    return (
        <>
            <div className="border-b-[1px] pb-4">
            <div className="flex flex-col pl-3 pt-3">
                <span className="text-base font-bold">Cluster Optimization Warning</span>
            </div>
            </div>
            <div className="bg-white rounded-lg pl-6 pr-6">
                <List
                    itemLayout="horizontal"
                    dataSource={errsSlice}
                    renderItem={(item: string, index) => (
                    <List.Item>
                        <List.Item.Meta
                            avatar={<FontAwesomeIcon icon={faTriangleExclamation} size="2x" className="pt-2" color="#feb108"/>}
                            title={<span className="font-bold">Corresponding resource: {item}</span>}
                            description={mapErrs.get(item)}
                        />
                    </List.Item>
                )}/>
            </div>
        </>
    )
}
