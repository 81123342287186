import { server } from "../config/props";
import type { Response } from "../type/common";
import { useApiFetch } from "../utils/use-api-fetch";
import { useCallback, useMemo } from 'react';

interface UserConfiguration {
  userId: string,
  allowSelfDeploy: boolean,
}

export function useUser() {
    const requests = useApiFetch();

    const getUserConfiguration = useCallback(async (): Promise<Response<UserConfiguration>> => {
        return requests.get(`${server}/api/v1/user/configuration`)
    }, [requests]);

    return useMemo(() => ({
      getUserConfiguration,
    }), [getUserConfiguration]);
}
