import { memo, useCallback, useEffect, useState } from "react";

import CodeBlock from "./CodeBlack";

import { useRebalance } from "../api/rebalance";
import { useCluster } from "../api/cluster";

import { Button, Flex, Steps, Switch, Typography } from "antd";

const Step = Steps.Step;

interface RemoveClusterModalContentProps {
  clusterID: string;
  onFinishRemoveCluster: () => void;
}

export default function RemoveClusterModalContent({
  clusterID,
  onFinishRemoveCluster
}: RemoveClusterModalContentProps) {
  const [step, setStep] = useState(0);

  const handleNextStep = useCallback(() => {
    setStep(step => step + 1);
  }, []);

  const handlePrevStep = useCallback(() => {
    setStep(step => step - 1);
  }, []);

  /** Restore Cluster */
  const cluster = useCluster();

  const [restoreSH, setRestoreSH] = useState("# Loading...");
  const [isRestoreSHLoading, setIsRestoreSHLoading] = useState(true);
  useEffect(() => {
    (async () => {
      const restoreData = await cluster.getRestoreSH(clusterID)
      if (restoreData.code !== 200) {
        console.error("Failed to fetch restore sh:", restoreData.message);
        return;
      }
      setIsRestoreSHLoading(false);
      setRestoreSH(restoreData.data!);
    })();
  }, [clusterID]);

  const [uninstallSH, setUninstallSH] = useState("# Loading...");
  const [isUninstallSHLoading, setIsUninstallSHLoading] = useState(true);
  useEffect(() => {
    (async () => {
      if (clusterID === "") {
        return;
      }
      const uninstallData = await cluster.getUninstallSH(clusterID)
      if (uninstallData.code !== 200) {

        console.error("Failed to fetch uninstall sh:", uninstallData.message);
        return;
      }

      setIsUninstallSHLoading(false);
      setUninstallSH(uninstallData.data!);
    })();
  }, [clusterID]);

  return (
    <Flex className="mt-6" justify="space-between" gap={32}>
      <Steps current={step} direction="vertical" size="small" style={{ flex: '0 0 auto', width: 'auto' }}>
        <Step title="Disable rebalance" />
        <Step title="Modify node group size" />
        <Step title="Delete optimized nodes" />
        <Step title="Uninstall" />
      </Steps>
      <Flex vertical align="flex-end" justify="space-between" style={{ flex: 1, width: 480 }}>
        {step === 0 && <DisableRebalance clusterID={clusterID} onNextStep={handleNextStep} />}
        {step === 1 && <ModifyNodeGroupSize restoreSH={restoreSH} isRestoreSHLoading={isRestoreSHLoading} onNextStep={handleNextStep} onPrevStep={handlePrevStep} />}
        {step === 2 && <DeleteOptimizedNodes onNextStep={handleNextStep} onPrevStep={handlePrevStep} />}
        {step === 3 && <Uninstall uninstallSH={uninstallSH} isUninstallSHLoading={isUninstallSHLoading} onFinishRemoveCluster={onFinishRemoveCluster} onPrevStep={handlePrevStep} />}
      </Flex>
    </Flex>
  )
}

interface DisableRebalanceProps {
  clusterID: string;
  onNextStep: () => void;
};

const DisableRebalance = memo(function DisableRebalance({ clusterID, onNextStep }: DisableRebalanceProps) {
  const rebalance = useRebalance();
  // const cluster = useCluster();

  const [isLoading, setIsLoading] = useState(true);
  const [rebalanceEnabled, setRebalanceEnabled] = useState(false);

  useEffect(() => {
    async function fetchClusterRebalanceConfiguration() {
      const cfg = await rebalance.getRebalanceConfiguration(clusterID);
      if (cfg.code !== 200) {
        console.error("Failed to fetch cluster rebalance configuration:", cfg.message)
        return;
      }

      setIsLoading(false);
      setRebalanceEnabled(cfg.data?.enable || false);
    }
    fetchClusterRebalanceConfiguration();
  }, [clusterID])

  function handleRebalanceSwitch(checked: boolean, _: any) {
    setRebalanceEnabled(checked);
    if (checked) {
      rebalance.updateRebalanceConfiguration(clusterID, { enable: true, enableGPUNodePool: null, ec2NodeClassSpec: null, generalNodePoolSpec: null, gpuNodePoolSpec: null });
      return
    }
    rebalance.updateRebalanceConfiguration(clusterID, { enable: false, enableGPUNodePool: null, ec2NodeClassSpec: null, generalNodePoolSpec: null, gpuNodePoolSpec: null });
  }

  return (
    <>
      <div className="w-full mb-4">
        <Typography.Paragraph>
          You need to disable rebalance before you can remove your cluster from CloudPilot AI.
        </Typography.Paragraph>

        <div className="flex items-center">
          <label className="font-bold mr-4">CloudPilot AI rebalance</label>
          <Switch loading={isLoading} disabled={isLoading} checked={rebalanceEnabled} onClick={handleRebalanceSwitch} defaultChecked />
        </div>
      </div>
      <div>
        <Button type="primary" loading={isLoading} disabled={isLoading || rebalanceEnabled} onClick={onNextStep}>
          Next
        </Button>
      </div>
    </>
  );
});

interface ModifyNodeGroupSizeProps {
  restoreSH: string;
  isRestoreSHLoading: boolean;
  onNextStep: () => void;
  onPrevStep: () => void;
}

const ModifyNodeGroupSize = memo(function ModifyNodeGroupSize({ restoreSH, isRestoreSHLoading, onNextStep, onPrevStep }: ModifyNodeGroupSizeProps) {
  return (
    <>
      <div className="w-full mb-4">
        <Typography.Paragraph>
          Restore your node group to the correct desired size:
        </Typography.Paragraph>

        <CodeBlock code={restoreSH} />
      </div>
      <Flex gap={8}>
        <Button onClick={onPrevStep}>
          Previous
        </Button>
        <Button type="primary" loading={isRestoreSHLoading} disabled={isRestoreSHLoading} onClick={onNextStep}>
          I have ran the script
        </Button>
      </Flex>
    </>
  );
});

const drainNodes = "for node in $(kubectl get node -l node.cloudpilot.ai/managed=true 2> /dev/null | grep -v 'No resources found' | tail -n +2 | awk '{print $1}'); do kubectl drain $node --ignore-daemonsets --delete-emptydir-data --force; done";
const waitNodesRemoved = "while [[ $(kubectl get nodes -l node.cloudpilot.ai/managed=true -o json | jq -r '.items | length') -ne 0 ]]; do echo \"Waiting for CloudPilot AI nodes to be removed...\"; sleep 3; done";

interface DeleteOptimizedNodesProps {
  onNextStep: () => void;
  onPrevStep: () => void;
}

const DeleteOptimizedNodes = memo(function DeleteOptimizedNodes({ onNextStep, onPrevStep }: DeleteOptimizedNodesProps) {
  return (
    <>
      <div className="w-full mb-4">
        <Typography.Paragraph>
          Delete the optimized nodes created by CloudPilot AI:
        </Typography.Paragraph>
        <CodeBlock code={drainNodes} />
      </div>
      <div className="w-full mb-4">
        <Typography.Paragraph>
          And wait for the nodes created by CloudPilot AI to be removed:
        </Typography.Paragraph>
        <CodeBlock code={waitNodesRemoved} />
      </div>
      <Flex gap={8}>
        <Button onClick={onPrevStep}>
          Previous
        </Button>
        <Button type="primary" onClick={onNextStep}>
          I have ran the scripts
        </Button>
      </Flex>
    </>
  );
})

interface UninstallProps {
  uninstallSH: string;
  isUninstallSHLoading: boolean;
  onFinishRemoveCluster: () => void;
  onPrevStep: () => void;
}

const Uninstall = memo(function Uninstall({ uninstallSH, isUninstallSHLoading, onFinishRemoveCluster, onPrevStep }: UninstallProps) {
  const [isLoading, setIsLoading] = useState(false);

  const handleFinish = () => {
    setIsLoading(true);
    onFinishRemoveCluster();
  };

  return (
    <>
      <div className="w-full mb-4">
        <Typography.Paragraph>
          Uninstall CloudPilot AI from your cluster:
        </Typography.Paragraph>
        <CodeBlock code={uninstallSH} />
        <Typography.Paragraph className="my-4">
          And all the CloudPilot AI components will be removed from your kubernetes cluster.
        </Typography.Paragraph>
      </div>
      <Flex gap={8}>
        <Button onClick={onPrevStep}>
          Previous
        </Button>
        <Button type="primary" loading={isUninstallSHLoading || isLoading} onClick={handleFinish}>
          I have ran the script, finish removing cluster
        </Button>
      </Flex>
    </>
  );
})
