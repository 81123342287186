import { ActionType, ProColumns, ProTable } from "@ant-design/pro-components";
import { Button, Input, Modal, Select, Switch, Tooltip } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAws } from "@fortawesome/free-brands-svg-icons";
import { faCloud, faLocationDot, faPlus } from "@fortawesome/free-solid-svg-icons";
import { uid } from 'uid';

import { ClusterCostsSummary, ClusterStatus } from "../type/clustercostssummary";
import CodeBlock from "./CodeBlack";
import { useDemo } from "../api/demo";
import CloudPilotSpin from "./Spin";
import { useClusterCostsSummary, type ClusterCostsSummaryMethods } from "../api/clustercostssummary";
import { getCostSymbol } from "../utils/getsymbol";
import RemoveClusterModalContent from "./RemoveClusterModalContent";
import { useAgentSH } from "../api/agentsh";
import { useCluster } from "../api/cluster";

const baseColumns: ProColumns<ClusterCostsSummary>[] = [
  {
    title: "Cloud Provider",
    dataIndex: "cloudProvider",
    key: "cloudprovider",
    copyable: false,
    search: false,
    render: (text, record, _, action) => {
      return <FontAwesomeIcon icon={faAws} key="cloudprovider" className="w-10 h-5" />
    },
  },
  {
    disable: true,
    title: "Region",
    key: "region",
    dataIndex: "region",
    search: false,
  },
  {
    disable: true,
    title: "Monthly Cost",
    key: "monthlycost",
    dataIndex: "monthlyCost",
    sorter: (a, b) => a.nodesNumber - b.nodesNumber,
    search: false,
    render: (text, record, _, action) => [
      <span key="monthlycost">{getCostSymbol(record.region)}{(text as number).toFixed(3)}</span>
    ],
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    sorter: (a, _) => a.status === ClusterStatus.ClusterStatusOnline ? 1 : -1,
    hideInSearch: true,
    render: (text, record, _, action) => {
      return text === ClusterStatus.ClusterStatusOffline ?
        <div className="flex items-center space-x-2" key="statusOffline">
          <span className="bg-red-600 rounded-full w-4 h-4 inline-block"></span>
          <span>{text}</span>
        </div> :
        <div className="flex items-center space-x-2" key="statusOnline">
          <span className="bg-green-500 rounded-full w-4 h-4 inline-block"></span>
          <span>{text}</span>
        </div>
    },
  },
  {
    title: "Nodes",
    dataIndex: "nodesNumber",
    key: "nodesNumber",
    sorter: (a, b) => a.nodesNumber - b.nodesNumber,
    hideInSearch: true,
  },
  {
    title: "Cluster ID",
    dataIndex: "id",
    key: "clusterid",
    sorter: false,
    hideInSearch: true,
  },
]

interface ConnectClusterModalProps {
  addClusterModal: boolean;
  onCancel: () => void;
  footer: React.ReactNode;
  agentSH: string;
}

function ConnectClusterModal({ addClusterModal, onCancel, footer, agentSH }: ConnectClusterModalProps) {
  return (
    <Modal
      title="Connect your kubernetes cluster"
      open={addClusterModal}
      onCancel={onCancel}
      footer={footer}>
      <p className="font-bold mb-2">Open your terminal and run the following command</p>
      <p className="mb-2">Please make sure kubectl is installed before running the command.</p>
      <CodeBlock code={agentSH} />
      <p className="mt-2">
        <span className="font-bold">CloudPilot AI</span>'s read-only agent only has the access to your cloud resource data, and won't change your cluster configuration.
      </p>
    </Modal>
  )
}

interface UpgradeClusterModalProps {
  upgradeClusterModal: boolean;
  onCancel: () => void;
  footer: React.ReactNode;
  clusterID: string;
}

function UpgradeClusterModal({ upgradeClusterModal, onCancel, footer, clusterID }: UpgradeClusterModalProps) {
  const cluster = useCluster();

  const [upgradeSH, setUpgradeSH] = useState("");
  useEffect(() => {
    async function fetchUpgradeSH() {
      if (clusterID === "") {
        return;
      }
      const upgradeData = await cluster.getUpgradeSH(clusterID)
      if (upgradeData.code !== 200) {
        console.error("Failed to fetch upgrade sh:", upgradeData.message);
        return;
      }
      setUpgradeSH(upgradeData.data!);

    }
    fetchUpgradeSH();
  }, [clusterID]);
  return (
    <Modal
      title="Upgrade CloudPilot AI components"
      open={upgradeClusterModal}
      onCancel={onCancel}
      footer={footer}>
      <p className="font-bold mb-2">Open your terminal and run the following command</p>
      <p className="mb-2">Ensure that kubectl, Helm, and the AWS CLI are installed before executing the command.</p>
      <CodeBlock code={upgradeSH} />
      <p className="mb-2">After upgrading, you will have access to an expanded set of features in <span className="font-bold">CloudPilot AI</span>.</p>
    </Modal>
  )
}

interface RemoveClusterModalProps {
  removeClusterModal: boolean;
  onCancel: () => void;
  clusterID: string;
  onFinishRemoveCluster: () => void;
}

function RemoveClusterModal({ removeClusterModal, onCancel, clusterID, onFinishRemoveCluster }: RemoveClusterModalProps) {
  return (
    <Modal
      key={clusterID}
      title="Remove kubernetes cluster from CloudPilot AI"
      open={removeClusterModal}
      onCancel={onCancel}
      footer={null}
      width={1000}
    >
      <RemoveClusterModalContent clusterID={clusterID} onFinishRemoveCluster={onFinishRemoveCluster} />
    </Modal>
  )
}

async function waitClusterAdd(clustercostssummary: ClusterCostsSummaryMethods, summaries: ClusterCostsSummary[]) {
  const startTime = Date.now();
  while (Date.now() - startTime < 60000) {
    const { code, message, data } = await clustercostssummary.listAllClusterCostsSummary();
    if (code !== 200) {
      console.log("Failed to fetch clusters cost summary:", message);
      break;
    }

    if (data === undefined || data === null) {
      // wait 1s
      await new Promise((resolve) => setTimeout(resolve, 1000));
      continue;
    }

    let costReady = true;
    for (const summary of data) {
      if (summary.initialMonthlyCost === 0.0) {
        costReady = false;
      }
    }
    if (costReady && data.length > summaries.length) {
      break;
    }
    // wait 1s
    await new Promise((resolve) => setTimeout(resolve, 1000));
  }
}

async function waitClusterDisappear(clustercostssummary: ClusterCostsSummaryMethods, summaries: ClusterCostsSummary[]) {
  const startTime = Date.now();
  while (Date.now() - startTime < 60000) {
    const { code, message, data } = await clustercostssummary.listAllClusterCostsSummary();
    if (code !== 200) {
      console.log("Failed to fetch clusters cost summary:", message);
      break;
    }
    if (data !== undefined) {
      if (data === null && summaries.length === 1) {
        break;
      }
      if (data !== null && data.length < summaries.length) {
        break;
      }
    }
    // wait 1s
    await new Promise((resolve) => setTimeout(resolve, 1000));
  }
}

interface CostOverviewCardProps {
  summaries: ClusterCostsSummary[];
}

export default function ClustersList({ summaries }: CostOverviewCardProps) {
  const agentsh = useAgentSH();
  const cluster = useCluster();
  const clustercostssummary = useClusterCostsSummary();
  const demo = useDemo();

  const actionRef = useRef<ActionType>();

  const [addClusterModal, setAddClusterModal] = useState(false);
  const [addDemoClusterModal, setAddDemoClusterModal] = useState(false);

  const [removeClusterModal, setRemoveClusterModal] = useState(false);
  const [removeClusterID, setRemoveClusterID] = useState("");

  const [upgradeClusterModal, setUpgradeModal] = useState(false);
  const [upgradeClusterID, setUpgradeClusterID] = useState("");

  const [agentSH, setAgentSH] = useState("");
  useEffect(() => {
    async function fetchAgentSH() {
      const { code, message, data } =
        await agentsh.getAgentSH();
      if (code !== 200) {
        console.error("Failed to fetch agent sh:", message);
        return;
      }
      setAgentSH(data!);
    }
    fetchAgentSH();
  }, []);

  const navigate = useNavigate();
  const columns: ProColumns<ClusterCostsSummary>[] = [
    {
      title: "Cluster Name",
      dataIndex: "clusterName",
      key: "clustername",
      copyable: false,
      search: true,
      render: (text, record, _, action) => {
        return <a className="text-blue-800" key="clustername" onClick={() => {
          navigate("/cluster/" + record.id + "/computesavings");
        }}>
          {text}
        </a>
      },
    },
    ...baseColumns,
    {
      title: "Operation",
      valueType: "text",
      key: "operation",
      search: false,
      render: (text, record, _, action) => {
        return (<>
          <a className="text-blue-900" key="Remove" onClick={() => {
            setRemoveClusterID(record.id);
            setRemoveClusterModal(true);
          }}>
            Remove
          </a>
          {
            // The demo cluster doesn't need to upgrade
            !record.demo
            // If the cluster needs upgrade
            && ('needUpgrade' in record && record.needUpgrade)
            && (
              <>
                <span> | </span>
                <a className="text-blue-900" key="Upgrade" onClick={() => {
                  setUpgradeClusterID(record.id);
                  setUpgradeModal(true);
                }}>
                  Upgrade
                </a>
              </>
            )}
        </>);
      }
    },
  ];

  const ranScriptButton = (
    <Button key="ranScript" type="primary" onClick={async () => {
      setAddClusterState(<CloudPilotSpin />);
      await waitClusterAdd(clustercostssummary, summaries);
      window.location.reload();
    }}>
      I ran the script
    </Button>
  );
  const [addClusterState, setAddClusterState] = useState(ranScriptButton);

  const [demoClusterName, setDemoClusterName] = useState(() => "cloudpilot-cluster-demo-" + uid(8));
  const [demoClusterRegion, setDemoClusterRegion] = useState("us-east-2");
  const [addDemoClusterState, setAddDemoClusterState] = useState(<></>);

  const demoClusterRegionRef = useRef(demoClusterRegion);
  demoClusterRegionRef.current = demoClusterRegion;

  const addDemoCluster = (
    <Button key="addDemoCluster" type="primary" onClick={async () => {
      setAddDemoClusterState(<CloudPilotSpin />);
      await demo.createDemoCluster(demoClusterName, demoClusterRegionRef.current);
      await waitClusterAdd(clustercostssummary, summaries);
      window.location.reload();
    }}>
      Add
    </Button>
  );

  useEffect(() => {
    setAddDemoClusterState(addDemoCluster);
  }, [demoClusterName])

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    setDemoClusterName(event.target.value);
  }

  useEffect(() => {
    if (actionRef.current) {
      actionRef.current.reload();
    }
  }, [summaries])

  const handleFinishRemoveCluster = useCallback(async () => {
    await cluster.unregisterCluster(removeClusterID);
    await waitClusterDisappear(clustercostssummary, summaries);
    window.location.reload();
  }, [removeClusterID, summaries]);

  const upgradeClusterButton = (
    <Button type="primary" key="upgradeCluster" onClick={async () => {
      setUpgradeModal(false);
    }}>
      OK
    </Button>
  )

  return (
    <div>
      <ConnectClusterModal
        addClusterModal={addClusterModal}
        onCancel={() => { setAddClusterModal(false) }}
        footer={addClusterState}
        agentSH={agentSH}
      />
      <RemoveClusterModal
        removeClusterModal={removeClusterModal}
        onCancel={() => { setRemoveClusterModal(false) }}
        // footer={removeClusterFooter}
        clusterID={removeClusterID}
        onFinishRemoveCluster={handleFinishRemoveCluster}
      />
      <UpgradeClusterModal
        upgradeClusterModal={upgradeClusterModal}
        onCancel={() => { setUpgradeModal(false) }}
        footer={upgradeClusterButton}
        clusterID={upgradeClusterID}
      />
      <Modal
        title="Add demo cluster"
        open={addDemoClusterModal}
        onCancel={() => {
          setAddDemoClusterModal(false);
          setAddDemoClusterState(addDemoCluster);
        }}
        footer={addDemoClusterState}
      >
        <div>
          <span>Input demo cluster name and region:</span>
          <div className="flex flex-row">
            <Input
              size="large"
              className="w-4/6 h-10"
              placeholder="demo cluster name"
              prefix={<FontAwesomeIcon icon={faCloud} style={{ color: "#1677ff" }} />}
              value={demoClusterName}
              onChange={handleInputChange}
            />
            <div className="w-1" />
            <Select
              defaultValue="us-east-2"
              className="w-2/6 h-10"
              suffixIcon={<FontAwesomeIcon icon={faLocationDot} style={{ color: "#1677ff" }} />}
              onChange={(value) => setDemoClusterRegion(value)}
              options={[
                { value: 'us-east-2', label: 'us-east-2' },
                { value: 'cn-north-1', label: 'cn-north-1' },
              ]}
            />
          </div>
        </div>
      </Modal>
      <ProTable<ClusterCostsSummary>
        actionRef={actionRef}
        cardBordered
        request={async (params, sort, filter) => {
          let data = summaries;
          if (params.clustername) {
            data = summaries.filter((summary) => summary.clusterName.startsWith(params.clustername));
          }
          return { data: data, success: true };
        }}
        rowKey="id"
        columns={columns}
        search={{
          labelWidth: "auto",
        }}
        options={false}
        pagination={{
          pageSize: 10,
          onChange: (page) => console.log(page),
        }}
        dateFormatter="string"
        headerTitle="Clusters List"
        toolBarRender={() => [
          <Button
            icon={<FontAwesomeIcon icon={faPlus} />}
            onClick={() => {
              setAddClusterModal(true);
            }}
            type="primary"
          >
            Add Cluster
          </Button>,
          <Button
            icon={<FontAwesomeIcon icon={faPlus} />}
            onClick={() => {
              setAddDemoClusterModal(true);
            }}
            type="primary"
          >
            Add Demo Cluster
          </Button>,
        ]}
      />
    </div>
  );
};
