import { Response } from "../type/common";
import { DemoCluster } from "../type/democluster";
import { server } from "../config/props";
import { useApiFetch } from "../utils/use-api-fetch";
import { useCallback, useMemo } from 'react';

export function useDemo() {
    const requests = useApiFetch();

    const createDemoCluster = useCallback(async (clusterName: string, region: string): Promise<Response<string>> => {
        const demoCluster: DemoCluster = { name: clusterName, region: region };
        return requests.post(
            `${server}/api/v1/demo/clusters`,
            demoCluster
        );
    }, [requests]);

    const enableDemoClusterRebalanceController = useCallback(async (clusterID: string): Promise<Response<string>> => {
        return requests.get(
            `${server}/api/v1/demo/clusters/${clusterID}/rebalance`,
        );
    }, [requests]);

    return useMemo(() => ({
        createDemoCluster,
        enableDemoClusterRebalanceController,
    }), [
        createDemoCluster,
        enableDemoClusterRebalanceController,
    ]);
}
