import { server } from "../config/props";
import { ClusterCostOptimization } from "../type/clustercostoptimization";
import { Response } from "../type/common";
import { useApiFetch } from "../utils/use-api-fetch";
import { useCallback, useMemo } from "react";

export function useClusterCostOptimization() {
    const requests = useApiFetch();

    const getClusterCostOptimization = useCallback(async (clusterID: string): Promise<Response<ClusterCostOptimization>> => {
        return requests.get(`${server}/api/v1/costs/clusters/${clusterID}/optimization`);
    }, [requests]);

    return useMemo(() => ({
        getClusterCostOptimization,
    }), [getClusterCostOptimization]);
}
