import { Response } from "../type/common";
import { ClusterRebalanceStatus, ClusterRebalanceConfiguration } from "../type/rebalance";
import { ClusterWorkload, Workload } from "../type/clusterworkload";
import { server } from "../config/props";
import { useApiFetch } from "../utils/use-api-fetch";
import { useCallback, useMemo } from 'react';

export function useRebalance() {
    const requests = useApiFetch();

    const getRebalanceConfiguration = useCallback(async (clusterID: string): Promise<Response<ClusterRebalanceConfiguration>> => {
        return requests.get(`${server}/api/v1/rebalance/clusters/${clusterID}/configuration`);
    }, [requests]);

    const updateRebalanceConfiguration = useCallback(async (clusterID: string, cfg: ClusterRebalanceConfiguration): Promise<Response<string>> => {
        return requests.post(`${server}/api/v1/rebalance/clusters/${clusterID}/configuration`, cfg);
    }, [requests]);

    const getRebalanceStatus = useCallback(async (clusterID: string): Promise<Response<ClusterRebalanceStatus>> => {
        return requests.get(`${server}/api/v1/rebalance/clusters/${clusterID}/status`);
    }, [requests]);

    const getWorkloadRebalanceConfiguration = useCallback(async (clusterID: string): Promise<Response<ClusterWorkload>> => {
        return requests.get(`${server}/api/v1/rebalance/clusters/${clusterID}/workloads/configuration`);
    }, [requests]);

    const updateWorkloadRebalanceConfiguration = useCallback(async (clusterID: string, cfg: Workload): Promise<Response<string>> => {
        return requests.post(`${server}/api/v1/rebalance/clusters/${clusterID}/workloads/configuration`, cfg);
    }, [requests]);

    return useMemo(() => ({
        getRebalanceStatus,
        updateRebalanceConfiguration,
        getRebalanceConfiguration,
        getWorkloadRebalanceConfiguration,
        updateWorkloadRebalanceConfiguration,
    }), [
        getRebalanceStatus,
        updateRebalanceConfiguration,
        getRebalanceConfiguration,
        getWorkloadRebalanceConfiguration,
        updateWorkloadRebalanceConfiguration,
    ]);
}
