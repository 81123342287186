import { PageContainer, ProLayout } from "@ant-design/pro-components";
import { homeLayoutProps } from "../config/props";
import { Outlet } from "react-router";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt, faFileArchive, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";

import { RequireAuth } from "../components/Auth";
import { useAuth0 } from "@auth0/auth0-react";

export default function HomeLayout() {
  const [pathName, setPathName] = useState("/");
  const { logout, user } = useAuth0();
  const [avatarImg, setAvatarImg] = useState(user?.picture);
  const [avatarName, setAvatarName] = useState(user?.nickname);

  useEffect(() => {
    if (!user) {
      return;
    }
    setAvatarImg(user?.picture || "");
    setAvatarName(user?.nickname || "");
  }, [user])

  return (
    <RequireAuth>
      <ProLayout
        siderWidth={216}
        title="CloudPilot AI"
        logo="https://assets.cloudpilot.ai/logo/logo.svg"
        pageTitleRender={(props) => {
          return props.menu?.request?.name || ""
        }}
        avatarProps={{
          src: avatarImg,
          title: avatarName,
          size: "default",
        }}
        location={{ pathname: pathName }}
        actionsRender={() => {
          return [
            <FontAwesomeIcon icon={faRightFromBracket} onClick={() => {
              logout({ logoutParams: { returnTo: window.location.origin } });
            }} color="#1677ff" />
          ];
        }}
        menuItemRender={(item, defaultDom) => {
          return (
            <Link
              to={item.path || '/'}
              className={item.footer ? "ant-pro-menu-item-footer" : ""}
              onClick={() => (setPathName(item.path || "/"))}
            >
              {defaultDom}
            </Link>
          );
        }}
        links={[
          <a
            href="https://docs.cloudpilot.ai/"
            target="_blank"
            rel="noreferrer"
          >
            <div className="flex flex-row items-center gap-3 pl-2">
              <span className="ant-pro-base-menu-inline-item-icon">
                <FontAwesomeIcon className="flex items-center" icon={faFileAlt} style={{ fontSize: "1.4rem", width: '1.4rem', color: "#1677ff" }} />
              </span>
              <span className="ant-pro-base-menu-inline-item-text ant-pro-base-menu-inline-item-text-has-icon">Documentation</span>
            </div>
          </a>
        ]}
        {...homeLayoutProps}
      >
        <PageContainer>
          <Outlet />
        </PageContainer>
      </ProLayout>
    </RequireAuth>
  );
}
