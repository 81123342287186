import { Typography } from "antd";

export default function CPUCostDescription() {
  return (
    <div className="w-full flex flex-col justify-between bg-white mt-10">
      <div className="border-b-[1px]">
        <div className="flex flex-col px-6 py-3">
          <span className="text-base font-bold">How is the Cost calculated?</span>
        </div>
      </div>
      <div className="bg-white rounded-lg py-4 px-6">
        <Typography>
          <Typography.Paragraph>
            <ul>
              <li className="pl-0">
                Monthly cost uses an average of 730 hours per month (<code>(365 days * 24 hours) / 12 months</code>) for estimating the instance cost.
              </li>
            </ul>
          </Typography.Paragraph>
        </Typography>
      </div>
    </div>
  );
}
