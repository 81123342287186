import { server } from "../config/props";
import { Response } from "../type/common";
import { useApiFetch } from "../utils/use-api-fetch";
import { useCallback, useMemo } from 'react';

export function useRebalanceSH() {
    const requests = useApiFetch();

    const getRebalanceSH = useCallback(async (clusterID: string): Promise<Response<string>> => {
        return requests.get(`${server}/api/v1/rebalance/clusters/${clusterID}/sh`)
    }, [requests]);

    return useMemo(() => ({
        getRebalanceSH,
    }), [getRebalanceSH]);
}
