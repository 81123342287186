import { useCallback, useMemo } from "react";
import { Response, Request } from "../type/common";
import { useAuth0 } from "@auth0/auth0-react";

export function useApiFetch() {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const request = useCallback(async function request<T, R>(request: Request<T>): Promise<Response<R>> {
    const authToken = await getAccessTokenSilently();

    const response = await fetch(request.path, {
      method: request.method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(request.data),
    });
    if (!response.ok) {
      // Do not reload login page, it's not necessary
      let path = window.location.pathname.replace(/^\//, "").replace(/\/$/, "");
      if (response.status === 401 && path !== "login") {
        console.log(response);
        loginWithRedirect();
        return {
          code: response.status,
          message: "Unauthorized",
        };
      }
      return {
        code: response.status,
        message: response.statusText,
      };
    }
    const { code, message, data } = await response.json();
    return {
      code: code,
      message: message,
      data: data,
    };
  }, [getAccessTokenSilently, loginWithRedirect]);

  const get = useCallback(async function get<T>(path: string): Promise<Response<T>> {
    return request({ method: "GET", path });
  }, [request]);

  const post = useCallback(async function post<T, R>(path: string, data?: T): Promise<Response<R>> {
    return request({ method: "POST", path, data });
  }, [request])

  const del = useCallback(async function del<T>(path: string): Promise<Response<T>> {
    return request({ method: "DELETE", path });
  }, [request])

  return useMemo(() => ({
    get,
    post,
    del,
  }), [get, post, del]);
}
