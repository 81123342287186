import { useCallback, useMemo } from "react";
import { server } from "../config/props";
import { Response } from "../type/common";
import { useApiFetch } from "../utils/use-api-fetch";

export function useAgentSH() {
    const requests = useApiFetch();

    const getAgentSH = useCallback(async function getAgentSH(): Promise<Response<string>> {
        return requests.get(`${server}/api/v1/agent/sh`)
    }, [requests])

    return useMemo(() => ({
        getAgentSH
    }), [getAgentSH])
}
