import { PageContainer, ProLayout } from "@ant-design/pro-components";
import { getClusterLayoutProps } from "../config/props";
import { Outlet, useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { useParams } from 'react-router-dom';

import { RequireAuth } from "../components/Auth";
import { useAuth0 } from "@auth0/auth0-react";


export default function ClusterLayout() {
  const { clusterID } = useParams();
  const { logout, user } = useAuth0();

  const [avatarImg, setAvatarImg] = useState(user?.picture);
  const [avatarName, setAvatarName] = useState(user?.nickname);
  const [pathName, setPathName] = useState("/cluster");

  useEffect(() => {
    if (!user) {
      return;
    }
    setAvatarImg(user?.picture || "");
    setAvatarName(user?.nickname || "");
  }, [user])

  const navigate = useNavigate();
  if (!clusterID) {
    navigate("/clusterlist");
    return null;
  }

  const props = getClusterLayoutProps(clusterID);
  return (
    <RequireAuth>
      <ProLayout
        siderWidth={216}
        logo="https://assets.cloudpilot.ai/logo/logo.svg"
        title="CloudPilot AI"
        pageTitleRender={(props) => {
          return ""
        }}
        avatarProps={{
          src: avatarImg,
          title: avatarName,
          size: "default",
        }}
        location={{ pathname: pathName }}
        actionsRender={(props) => {
          return [
            <FontAwesomeIcon icon={faRightFromBracket} onClick={() => {
              logout({ logoutParams: { returnTo: window.location.origin } });
            }} color="#1677ff" />
          ];
        }}
        menuItemRender={(item, defaultDom) => (
          <Link to={item.path || '/cluster'} onClick={() => (setPathName(item.path || "/"))}>
            {defaultDom}
          </Link>
        )}
        links={[
          <a
            href="https://docs.cloudpilot.ai/"
            target="_blank"
            rel="noreferrer"
          >
            <div className="flex flex-row items-center gap-3 pl-2">
              <span className="ant-pro-base-menu-inline-item-icon">
                <FontAwesomeIcon className="flex items-center" icon={faFileAlt} style={{ fontSize: "1.4rem", width: '1.4rem', color: "#1677ff" }} />
              </span>
              <span className="ant-pro-base-menu-inline-item-text ant-pro-base-menu-inline-item-text-has-icon">Documentation</span>
            </div>
          </a>
          // {
          //   path: "",
          //   name: "Documentation",
          //   icon: <FontAwesomeIcon icon={faFile} style={{ fontSize: "1.4rem", width: '1.4rem', color: "#1677ff" }} />,
          //   target: "_blank",
          // }
        ]}
        {...props}
      >
        <PageContainer className="h-full">
          <Outlet />
        </PageContainer>
      </ProLayout>
    </RequireAuth >
  );
}
