import { useCallback, useMemo } from "react";
import { server } from "../config/props";
import { ClusterCostsSummary } from "../type/clustercostssummary";
import { Response } from "../type/common";
import { useApiFetch } from "../utils/use-api-fetch";

export interface ClusterCostsSummaryMethods {
    listAllClusterCostsSummary: () => Promise<Response<ClusterCostsSummary[]>>,
    getClusterCostsSummary: (clusterID: string) => Promise<Response<ClusterCostsSummary>>,
}

export function useClusterCostsSummary(): ClusterCostsSummaryMethods {
    const requests= useApiFetch();

    const listAllClusterCostsSummary = useCallback(async function listAllClusterCostsSummary(): Promise<Response<ClusterCostsSummary[]>> {
        return requests.get<ClusterCostsSummary[]>(`${server}/api/v1/costs/clusters/summary`)
    }, [requests])

    const getClusterCostsSummary = useCallback(async function getClusterCostsSummary(clusterID: string): Promise<Response<ClusterCostsSummary>> {
        return requests.get<ClusterCostsSummary>(`${server}/api/v1/costs/clusters/${clusterID}/summary`);
    }, [requests])

    return useMemo(() => ({
        listAllClusterCostsSummary,
        getClusterCostsSummary,
    }), [listAllClusterCostsSummary, getClusterCostsSummary])
}
