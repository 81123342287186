import { faChartColumn, faDatabase, faPiggyBank, faSackDollar, faServer, faSliders, faStethoscope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const server = process.env.REACT_APP_CLOUDPILOT_API_ENDPOINT || "https://api.cloudpilot.ai";
const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN || "dev-7pdhfkst4gp1rybs.us.auth0.com";
const auth0Audience = process.env.REACT_APP_AUTH0_AUDIENCE || "https://dev-7pdhfkst4gp1rybs.us.auth0.com/api/v2/";
const auth0ClientID = process.env.REACT_APP_AUTH0_CLIENTID || "Bi4klmBWjIskoXabiHAqqxxjocgGPDNl";

const homeLayoutProps = {
    route: {
      path: "/clusterlist",
      routes: [
        {
            path: "/clusterlist",
            name: "Cluster list",
            icon: <FontAwesomeIcon icon={faServer} style={{ fontSize: "1.4rem", color: "#1677ff" }} />,
        },
      ],
    },
};

function getClusterLayoutProps(clusterID: string) {
  return {
    route: {
      path: `/cluster/${clusterID}`,
      routes: [
        {
            path: "/clusterlist",
            name: "Cluster list",
            icon: <FontAwesomeIcon icon={faServer} style={{ fontSize: "1.4rem", color: "#1677ff" }} />,
        },
        {
            isDivider: true,
        },
        {
            path: `/cluster/${clusterID}/computesavings`,
            name: "Compute savings",
            icon: <FontAwesomeIcon icon={faSackDollar} style={{ fontSize: "1.4rem", color: "#1677ff" }} />,
        },
        {
            path: `/cluster/${clusterID}/computerebalance`,
            name: "Compute rebalance",
            icon: <FontAwesomeIcon icon={faChartColumn} style={{ fontSize: "1.4rem", color: "#1677ff" }} />,
        },
        {
          path: `/cluster/${clusterID}/commitment`,
          name: "Commitment",
          icon: <FontAwesomeIcon icon={faPiggyBank} style={{ fontSize: "1.4rem", color: "#1677ff" }} />,
          hideInMenu: true,
        },
        {
          path: `/cluster/${clusterID}/workloadsdiagnosis`,
          name: "Workload diagnosis",
          icon: <FontAwesomeIcon icon={faStethoscope} style={{ fontSize: "1.4rem", color: "#1677ff" }} />,
        },
        {
          path: `/cluster/${clusterID}/storagesaving`,
          name: "Storage saving",
          icon: <FontAwesomeIcon icon={faDatabase} style={{ fontSize: "1.4rem", color: "#1677ff" }} />,
        },
        {
          path: `/cluster/${clusterID}/configuration`,
          name: "Configuration",
          icon: <FontAwesomeIcon icon={faSliders} style={{ fontSize: "1.4rem", color: "#1677ff" }} />,
          routes: [
            {
              path: `/cluster/${clusterID}/configuration/workload`,
              name: "Workload",
            },
            {
              path: `/cluster/${clusterID}/configuration/nodetemplate`,
              name: "Node template",
            }
          ],
        },
      ],
    },
  };
}

export { server, auth0Domain, auth0Audience, auth0ClientID, homeLayoutProps, getClusterLayoutProps };