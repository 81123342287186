import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { email } from "../components/EmailVerification";
import { loading } from "../components/Loading";

export default function Loading() {
    const { isAuthenticated, isLoading, user, getAccessTokenSilently, error, loginWithRedirect } = useAuth0();

    const loadingPage = (
        <loading.RequestLoading />
    );
    const [page, setPage] = useState(loadingPage);

    const navigate = useNavigate();
    useEffect(() => {
        async function setTokenAndUserInfo() {
            if (isLoading) {
                return;
            }
            if (error) {
                setPage(<email.VerifyEmail />);
                return;
            }
            if (!isAuthenticated) {
                loginWithRedirect();
                return;
            }

            // We preload the access token during the first page load so we can start firing requests earlier
            getAccessTokenSilently().catch((e) => {
                console.error('Preload access token failed:', e);
            });

            navigate("/clusterlist");
        }
        setTokenAndUserInfo();
    }, [isLoading, isAuthenticated, user, error, getAccessTokenSilently, navigate, loginWithRedirect]);

    return page;
}
