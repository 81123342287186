import { Button, Space } from "antd";
import { useNavigate, useParams } from "react-router";

import SavingsPlanUtilizationHeader from "../components/SavingsPlanUtilizationHeader";
import SavingsPlanWorks from "../components/SavingsPlanWork";
import SavingsPlanList from "../components/SavingsPlanList";
import { useEffect, useState } from "react";
import { ClusterCostsSummary } from "../type/clustercostssummary";
import { useClusterCostsSummary } from "../api/clustercostssummary";
import { loading } from "../components/Loading";
import { useRebalance } from "../api/rebalance";
import { useCluster } from "../api/cluster";
import { isRebalanceComponentReady } from "../utils/rebalance";
import { getCostSymbol } from "../utils/getsymbol";
import { ClusterBilling } from "../type/clusterbilling";


export default function Commitment() {
    const { clusterID } = useParams();

    const clustercostssummary = useClusterCostsSummary();
    const rebalance = useRebalance();
    const cluster = useCluster();

    const [rebalanceComponentReady, setRebalanceComponentReady] = useState(true);
    const [clusterSummary, setClusterSummary] = useState<ClusterCostsSummary>();
    const [clusterbilling, setClusterbilling] = useState<ClusterBilling>();
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchClusterCostsSummary() {
          if (!clusterID) {
            return;
          }
          const summary = await clustercostssummary.getClusterCostsSummary(clusterID);
          if (summary.code !== 200) {
            console.error("Failed to fetch cluster costs summary:", summary.message);
            return;
          }
          setClusterSummary(summary.data!);

          const rebalanceStatus = await rebalance.getRebalanceStatus(clusterID);
          if (rebalanceStatus.code !== 200) {
            console.error("Failed to fetch cluster rebalance status:", rebalanceStatus.message);
            return;
          }
          setRebalanceComponentReady(isRebalanceComponentReady(rebalanceStatus.data!));


          const billing = await cluster.getClusterBilling(clusterID);
          if (billing.code !== 200) {
            console.error("Failed to fetch cluster billing:", billing.message);
            return;
          }
          setClusterbilling(billing.data!);
        }
        fetchClusterCostsSummary();
    }, [clusterID]);

    if (!clusterSummary || !clusterID || !clusterbilling) {
        return <loading.RequestLoading />;
    }

    if (!rebalanceComponentReady) {
        return (
            <div className="flex flex-col bg-white rounded-lg p-6 mt-40 pt-36">
                <div className="flex flex-row w-full justify-center items-center mb-10">
                    <img alt="logo" className="w-24 h-24 object-center mx-auto" src="https://assets.cloudpilot.ai/logo/logo.svg" />
                </div>
                <div className="flex flex-row w-full justify-center items-center mb-10">
                    <span className="text-lg">
                        <b>CloudPilot AI</b> rebalance components should be installed before commitment management(Savings Plan, and so on)!
                    </span>
                </div>
                <div className="flex flex-row w-full justify-center items-center mb-10">
                    <Button
                        type="primary"
                        onClick={() => {
                            navigate(`/cluster/${clusterID}/computesavings`)
                        }}
                    >
                        Go to install
                    </Button>
                </div>
            </div>
        ) 
    }

    const symbol = getCostSymbol(clusterSummary.region);
    return (
        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
            <div className="w-full h-full">
                <SavingsPlanUtilizationHeader clusterBilling={clusterbilling} symbol={symbol} />
                <SavingsPlanList savingsPlan={clusterbilling?.awsBilling?.savingsPlans} symbol={symbol} />
                <SavingsPlanWorks />
            </div>
        </Space>
    )
}
