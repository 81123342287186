import { CalendarOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";

interface BookADemoModalContentProps {
  open: boolean;
  onClose: () => void;
}

export default function BookADemoModal({ open, onClose }: BookADemoModalContentProps) {
  return (
    <Modal
      title="Are you ready to start optimizing your K8s clusters?"
      open={open}
      onCancel={onClose}
      footer={<Button
        type="primary"
        href="https://cal.com/cloudpilot.ai/30min"
        target="_blank"
        rel="noopener noreferrer external"
        icon={<CalendarOutlined />}
      >
        Schedule a Demo
      </Button>}
    >
      <div>
        <p>
          Discover how CloudPilot AI intelligently manages spot instances for maximum efficiency. Book a free demo with CloudPilot AI Team today!
        </p>
      </div>
    </Modal>
  );
}
