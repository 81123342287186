function RequestLoading() {
    return (
        <div className="flex flex-col bg-white rounded-lg p-6 mt-40 pt-36">
            <div className="flex flex-row w-full justify-center items-center mb-10">
                <img alt="logo" className="w-24 h-24 object-center mx-auto animate-bounce" src="https://assets.cloudpilot.ai/logo/logo.svg" />
            </div>
            <div className="flex flex-row w-full justify-center items-center mb-10">
                <span className="text-lg">
                    <b>Loading...</b>
                </span>
            </div>
        </div>
    )
}

export const loading = {
    RequestLoading,
}
