import { Switch } from "antd";
import { useParams } from "react-router";
import { useEffect, useState } from "react";

import { useRebalance } from "../api/rebalance"

export default function RebalaceInTime() {
    const { clusterID } = useParams();

    const rebalance = useRebalance();

    const [rebalanceEnabled, setRebalanceEnabled] = useState(false);

    useEffect(() => {
        async function fetchClusterRebalanceConfiguration() {
            if (!clusterID) {
                return;
            }
            const cfg = await rebalance.getRebalanceConfiguration(clusterID);
            if (cfg.code !== 200) {
                console.error("Failed to fetch cluster costs optimization:", cfg.message);
                return;
            }
            setRebalanceEnabled(cfg.data?.enable ?? false);
        }
        fetchClusterRebalanceConfiguration();
    }, [clusterID])

    function handleRebalanceSwitch(checked: boolean, _: any) {
        if (!clusterID) {
            return;
        }
        setRebalanceEnabled(checked);
        if (checked) {
            rebalance.updateRebalanceConfiguration(clusterID, { enable: true, enableGPUNodePool: null, ec2NodeClassSpec: null, generalNodePoolSpec: null, gpuNodePoolSpec: null });
            return
        }
        rebalance.updateRebalanceConfiguration(clusterID, { enable: false, enableGPUNodePool: null, ec2NodeClassSpec: null, generalNodePoolSpec: null, gpuNodePoolSpec: null });
    }

    return (
        <div className="flex flex-col bg-white rounded-lg p-6 mt-40 pt-36">
            <div className="flex flex-row w-full justify-center items-center mb-10">
                <img alt="logo" className="w-24 h-24 object-center mx-auto" src="https://assets.cloudpilot.ai/logo/logo.svg" />
            </div>
            <div className="flex flex-row w-full justify-center items-center mb-10">
                <span className="text-lg">
                    <b>CloudPilot AI </b>
                    is optimizing your cluster in real time with <b>AI engine</b>!
                </span>
            </div>
            <div className="flex flex-row w-full justify-center items-center mb-10">
                <label className="min-w-48 text-base font-bold">Enable CloudPilot AI:</label>
                <Switch checked={rebalanceEnabled} onClick={handleRebalanceSwitch} defaultChecked />
            </div>
        </div>
    );
}
