import { useEffect, useState } from "react";
import { useParams } from "react-router";

import { ClusterRebalanceConfiguration, ClusterRebalanceState, ClusterRebalanceStatus } from "../type/rebalance";
import { useRebalance } from "../api/rebalance";
import { loading } from "../components/Loading";
import RebalancePlan from "./RebalancePlan";
import RebalaceProcess from "./RebalanceProcess";
import RebalaceInTime from "./RebalanceInTime";

export default function Rebalance() {
    const { clusterID } = useParams();

    const rebalance = useRebalance();

    const [ rebalanceCfg, setRebalanceCfg ] = useState<ClusterRebalanceConfiguration>();
    const [ rebalanceStatus, setRebalanceStatus ] = useState<ClusterRebalanceStatus>();
    const [ showLoading, setShowLoading ] = useState(false);
    const [refreshKey, setRefreshKey] = useState(0);

    const triggerRefresh = () => {
        setShowLoading(true);
        setRefreshKey((prevKey) => prevKey + 1);
    };

    useEffect(() => {
        async function fetchClusterRebalanceConfigurationAndStatus() {
            if (!clusterID) {
                return;
            }
            const cfg = await rebalance.getRebalanceConfiguration(clusterID);
            if (cfg.code !== 200) {
                console.error("Failed to fetch cluster costs optimization:", cfg.message);
                return;
            }
            setRebalanceCfg(cfg.data!);

            const status = await rebalance.getRebalanceStatus(clusterID);
            if (status.code !== 200) {
                console.error("Failed to fetch cluster rebalance status:", status.message);
                return;
            }
            setShowLoading(false);
            setRebalanceStatus(status.data!);
        }
        fetchClusterRebalanceConfigurationAndStatus();
    }, [clusterID, refreshKey])

    if (!rebalanceCfg || !rebalanceStatus || showLoading) {
        return <loading.RequestLoading />;
    }

    if (rebalanceCfg.enable && rebalanceStatus.state !== ClusterRebalanceState.ClusterRebalanceStateSuccess) {
        return <RebalaceProcess triggerRefresh={triggerRefresh} />;
    }
    if (rebalanceCfg.enable && rebalanceStatus.state === ClusterRebalanceState.ClusterRebalanceStateSuccess) {
        return <RebalaceInTime />;
    }

    return <RebalancePlan triggerRefresh={triggerRefresh} />;
}
