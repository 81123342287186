import { Space } from "antd";

import CostOverviewCard from "../components/CostOverviewCard";
import ClustersList from "../components/ClustersList";
import { useEffect, useState } from "react";
import { useClusterCostsSummary } from "../api/clustercostssummary";
import { ClusterCostsSummary } from "../type/clustercostssummary";
import { loading } from "../components/Loading";
import CoreFeature from "../components/CoreFeature";


interface State {
    summaries: ClusterCostsSummary[];
    loading: boolean;
    error: string | null;
}

export default function ClutserList() {
    const clustercostssummary = useClusterCostsSummary();

    const [state, setState] = useState<State>({
        summaries: [],
        loading: true,
        error: null,
    });

    useEffect(() => {
        async function fetchAllClusterCostsSummary() {
            const { code, message, data } =
                await clustercostssummary.listAllClusterCostsSummary();
            if (code !== 200) {
                console.error("Failed to fetch cluster costs summary:", message);
                return;
            }
            setState({
                summaries: data! || [],
                loading: false,
                error: null,
            });
        }
        fetchAllClusterCostsSummary();
    }, []);

    useEffect(() => {
        // TODO: send notification
    }, [state.error]);

    if (state.loading) {
        return <loading.RequestLoading />
    }

    return (
         <Space direction="vertical" size="middle" style={{ display: "flex" }}>
             <CostOverviewCard summaries={state.summaries}/>
             <ClustersList summaries={state.summaries} />
             { /* <HomeADCard /> */ }
             <CoreFeature />
         </Space>
    )
}
