import { CalendarOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import { useCallback, useRef, useState } from "react";

interface ClusterConfigurationWarningOverlayProps {
  hasClusterOptimizationErrors: boolean;
}

export default function ClusterConfigurationWarningOverlay({ hasClusterOptimizationErrors }: ClusterConfigurationWarningOverlayProps) {
  const [optimizationWarningModalOpen, setOptimizationWarningModalOpen] = useState(hasClusterOptimizationErrors);
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div className="" ref={containerRef}>
      <Modal
        title="Cluster Configuration Warning"
        open={optimizationWarningModalOpen}
        onCancel={useCallback(() => setOptimizationWarningModalOpen(false), [])}
        maskClosable={false}
        getContainer={() => containerRef.current || document.body}
        centered
        className="relative flex"
        classNames={{
          mask: "!absolute inset-0 h-full backdrop-blur-sm !bg-white/40",
          wrapper: "!absolute !overflow-hidden !flex !items-center !justify-center",
        }}
        footer={
        <Button
          type="primary"
          href="https://cal.com/cloudpilot.ai/30min"
          target="_blank"
          rel="noopener noreferrer external"
          icon={<CalendarOutlined />}
        >
          Schedule a Demo
        </Button>
        }
      >
        <div>
          <p>
            Please review the warning information below. Due to the presence of warnings, the optimization plan may not be accurate. You can sechedule a demo with our team to get more information.
          </p>
        </div>

      </Modal>
    </div>
  );
};
